<template>
  <div class="container-main-content auth-success">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-4">Đăng ký thành công!</h4>
        <button type="button" class="btn btn-primary" @click="handleLogin">
          ĐĂNG NHẬP NGAY
        </button>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      currentQueries: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?"))
        : "",
    };
  },
  methods: {
    handleLogin() {
      this.$router.push({ name: "logout" });
    },
  },
};
</script>

<style>
.bg-main {
  display: block;
}
</style>
